<script>
    import HeadsetsList from '@components/Headsets/HeadsetsList.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'

    export default {
        name: 'StartFormationHeadsetList',
        components: { ConnectedStatus, BatteryLevel, HeadsetsList },
        props: {
            headsetsAppDriven: {
                type: Array,
                default: () => [],
            },
        },
    }
</script>

<template>
    <HeadsetsList
        :columns="[
            {
                name: 'state',
            },
        ]"
        :disabled="true"
        :headsets="headsetsAppDriven"
        :max="1"
        :no-headset="$t('headsets.pilotage.steps.step1.no_headset_selected')"
    >
        <template #state="{ headset }">
            <ra-stack
                direction="horizontal"
                gap="4"
            >
                <img
                    :src="headset.image"
                    alt=""
                    class="object-fit-contain"
                />
                <ra-stack gap="1">
                    <ra-typo
                        bold
                        max-width="150px"
                        no-word-wrap
                        variant="subtitle"
                    >
                        {{ headset.name || $t('headsets._no_headset') }}
                    </ra-typo>
                    <ra-stack
                        alignement="center"
                        direction="horizontal"
                        gap="6"
                    >
                        <connected-status :headset="headset" />
                        <battery-level :value="headset.state.batteryLevel" />
                    </ra-stack>
                </ra-stack>
            </ra-stack>
        </template>
        <template #settings="props">
            <v-btn
                :color="props.isSelected ? 'primary' : 'primary-lighten-5'"
                class="text-white"
                density="comfortable"
                icon="mdi-cog"
                variant="plain"
            />
        </template>
    </HeadsetsList>
</template>
