<script>
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import { Headset } from '@libs/MDM/core/Headset.js'

    export default {
        components: { BatteryLevel },
        props: {
            headset: {
                type: Headset,
                required: true,
            },
            display: {
                type: Array,
                default: () => ['status', 'network', 'battery'],
            },
            compact: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>

<template>
    <ra-stack
        direction="horizontal"
        alignement="center"
        width="auto"
        flex="0 0 auto"
    >
        <ra-typo
            v-if="display.includes('status')"
            :tooltip="headset.statusLabel"
            span
        >
            <ra-icon
                style="font-size: 10px"
                :color="headset.statusColor"
                >mdi-circle</ra-icon
            >
        </ra-typo>
        <ra-typo
            v-if="display.includes('network') && headset.isConnected"
            :tooltip="headset.networkInformations.name"
            span
        >
            <ra-icon weight="700">fa-wifi</ra-icon>
        </ra-typo>
        <battery-level
            v-if="display.includes('battery')"
            :value="headset.batteryLevel"
            :compact="compact"
            :style="{
                opacity: headset.isConnected ? 1 : 0.5,
            }"
        />
    </ra-stack>
</template>

<style scoped lang="scss"></style>
