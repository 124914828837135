import { createApp } from 'vue'
import { createPinia } from 'pinia'
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
import VueDragscroll from 'vue-dragscroll'
import VueDatePicker from '@vuepic/vue-datepicker'

import App from '@/App.vue'
import router from '@core/router/router'
import i18n from '@core/i18n'

import OnlyWeb from '@components/tools/OnlyWeb.vue'
import OnlyTablet from '@components/tools/OnlyTablet.vue'
import UIKitConfig from '@config/UIKitConfig.js'
import RaUIKit from '@realityteam/reality-academy-ui-kit'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-skeletor/dist/vue-skeletor.css'
import { useLanguageStore } from '@store/lang.js'
import { loadUserStore } from '@config/loadUserStore.js'
import VueGtag from 'vue-gtag'

/* PUGINS */
async function plugins(app) {
    const pinia = createPinia()
    pinia.use(({ store }) => {
        store.$router = router
    })
    app.use(pinia)

    useLanguageStore(true)
    await loadUserStore()
    app.use(router)
    app.use(i18n)
    app.use(RaUIKit.create(UIKitConfig(app)))
    app.use(VueDragscroll)
    app.use(VueGtag, {
        config: {
            id: import.meta.env.VITE_GA_ID,
            disabled: import.meta.env.VITE_APP_ENV !== 'production',
        },
    })
    return app
}

/* MIXINS */
function mixins(app) {
    app.mixin({
        computed: {
            $isWeb() {
                return !window.IS_TABLET
            },
        },
    })
}

/* COMPONENTS */
function components(app) {
    app.component('web', OnlyWeb)
    app.component('tablet', OnlyTablet)
    app.component('qr-code', QrcodeVue)
    app.component('vue-date-picker', VueDatePicker)
}

function directives(app) {
    app.directive('web', {
        mounted(el) {
            if (!window.IS_TABLET) el.style.display = 'block'
            else el.style.display = 'none'
        },
    })

    app.directive('tablet', {
        mounted(el) {
            if (window.IS_TABLET) el.style.display = 'block'
            else el.style.display = 'none'
        },
    })
}

// Filters

function filters(app) {
    //create a vue filter
    app.config.globalProperties.$filters = {
        formatDate(date, format = 'L', lang = null) {
            moment.locale('fr', {
                months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
                    '_'
                ),
                monthsShort:
                    'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split(
                        '_'
                    ),
                monthsParseExact: true,
                weekdays:
                    'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split(
                        '_'
                    ),
                weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
                weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
                weekdaysParseExact: true,
                longDateFormat: {
                    LT: 'HH:mm',
                    LTS: 'HH:mm:ss',
                    L: 'DD/MM/YYYY',
                    LL: 'D MMMM YYYY',
                    LLL: 'D MMMM YYYY HH:mm',
                    LLLL: 'dddd D MMMM YYYY HH:mm',
                },
                calendar: {
                    sameDay: '[Aujourd’hui à] LT',
                    nextDay: '[Demain à] LT',
                    nextWeek: 'dddd [à] LT',
                    lastDay: '[Hier à] LT',
                    lastWeek: 'dddd [dernier à] LT',
                    sameElse: 'L',
                },
                relativeTime: {
                    future: 'dans %s',
                    past: 'il y a %s',
                    s: 'quelques secondes',
                    m: 'une minute',
                    mm: '%d minutes',
                    h: 'une heure',
                    hh: '%d heures',
                    d: 'un jour',
                    dd: '%d jours',
                    M: 'un mois',
                    MM: '%d mois',
                    y: 'un an',
                    yy: '%d ans',
                },
                dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
                ordinal: function (number) {
                    return number + (number === 1 ? 'er' : 'e')
                },
                meridiemParse: /PD|MD/,
                isPM: function (input) {
                    return input.charAt(0) === 'M'
                },
                // In case the meridiem units are not separated around 12, then implement
                // this function (look at locale/id.js for an example).
                // meridiemHour : function (hour, meridiem) {
                //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
                // },
                meridiem: function (hours, minutes, isLower) {
                    return hours < 12 ? 'PD' : 'MD'
                },
                week: {
                    dow: 1, // Monday is the first day of the week.
                    doy: 4, // Used to determine first week of the year.
                },
            })
            const str = moment(String(date)).format(format)
            if (str == 'Invalid date') return date
            return str
        },
        formatTime(time, format = 'HH[h]mm', lang = null) {
            moment.locale(lang || i18n.global.locale)
            const str = moment(String(time), 'HH:mm:ss').format(format)
            if (str == 'Invalid date') return time
            return str
        },
        padStart(number, length = 2, char = '0') {
            if (Number.isNaN(Number(number))) return number
            return String(number).padStart(length, char)
        },
    }
}

export default async () => {
    const app = createApp(App)
    components(app)
    mixins(app)
    await plugins(app)
    filters(app)
    directives(app)
    return app
}
