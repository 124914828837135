export const CompaniesRoutesName = {
    index: 'companies.index',
    show: 'companies.show',
    formers: 'companies.formers',
    learners: 'companies.learners',
    headsets: 'companies.headsets',
    profile: 'companies.profile',
    licenses: 'companies.licenses',
    stats: 'companies.stats',
    formerProfile: 'companies.formerProfile',
    learnerProfile: 'companies.learnerProfile',
    infoTab: 'companies.infoTab',
    formationTab: 'companies.formationTab',
    sessionTab: 'companies.sessionTab',
}
