<script>
    export default {
        data() {
            return {}
        },

        computed: {
            content() {
                return [
                    {
                        title: 'headsets.settings.headset_application.title',
                        properties: {
                            'headsets.settings.headset_application.last_launch':
                                '22/10/24',
                            'headsets.settings.headset_application.kiosk_mode':
                                'Activé',
                            'headsets.settings.headset_application.lang':
                                'Français',
                        },
                    },
                    {
                        title: 'headsets._name',
                        properties: {
                            'headsets.properties.model.label': 'Pico G2 4K',
                            'headsets.properties.version.headset': '1.0.0',
                            'headsets.properties.version.android': '1.0.0',
                            'headsets.properties.disk.label': {
                                content: '38Go/42Go',
                                attrs: {
                                    color: 'error',
                                },
                            },
                            'headsets.properties.serialNumber.label':
                                '123456789',
                            'headsets.properties.control.controller': 'Manette',
                        },
                    },
                    {
                        title: 'headsets.settings.headset_network.title',
                        properties: {
                            'headsets.settings.headset_network.name':
                                'Réseau wifi',
                            'headsets.settings.headset_network.ip': '8.8.8.8',
                            'headsets.settings.headset_network.mac':
                                '1D:1D:1D:1D:1D:1D',
                        },
                    },
                ]
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="4"
    >
        <ra-card
            v-for="card in content"
            :key="card.title"
        >
            <template #title>
                {{ $t(card.title) }}
            </template>
            <ra-box padding="3">
                <ra-typo variant="small">
                    <table>
                        <tr
                            v-for="(prop, i) in Object.keys(card.properties)"
                            :key="'prop-' + i"
                        >
                            <td>
                                <ra-box style="width: 160px">
                                    <ra-typo
                                        span
                                        semi
                                    >
                                        {{ $t(prop) }}
                                    </ra-typo>
                                </ra-box>
                            </td>
                            <td>
                                <template v-if="card.properties[prop]?.content">
                                    <ra-typo
                                        v-bind="card.properties[prop]?.attrs"
                                    >
                                        {{ $t(card.properties[prop]?.content) }}
                                    </ra-typo>
                                </template>
                                <ra-typo v-else>
                                    {{ $t(card.properties[prop]) }}
                                </ra-typo>
                            </td>
                        </tr>
                    </table>
                </ra-typo>
            </ra-box>
        </ra-card>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
