import { adminFormersRoutesMeta as meta } from './meta.js'
import { FormersRoutesNames } from './Formers.name.js'
import path from 'path'

export default [
    // {
    //     path: '/companies/:company_id/formers/:former_id',
    //     component: () => import('@pages/Formers/FormerShowPage.vue'),
    //     children: [
    //         {
    //             path: '',
    //             name: FormersRoutesNames.show,
    //             redirect: { name: FormersRoutesNames.profile },
    //             meta,
    //         },
    //         {
    //             path: 'profile',
    //             name: FormersRoutesNames.profile,
    //             meta: {
    //                 relatedName: FormersRoutesNames.show,
    //                 ...meta,
    //             },
    //             component: () => import('@components/Formers/FormerView.vue'),
    //         },
    //         {
    //             path: 'learners',
    //             name: FormersRoutesNames.learners,
    //             component: () =>
    //                 import('@components/Learners/LearnerTableList.vue'),
    //         },
    //         {
    //             path: 'headsets',
    //             name: FormersRoutesNames.headsets,
    //             component: () =>
    //                 import('@components/Headsets/SubHeadsetsTab.vue'),
    //         },
    //         {
    //             path: 'stats',
    //             name: FormersRoutesNames.stats,
    //             component: () =>
    //                 import('@components/Stats/SubStatistiquesView.vue'),
    //             meta,
    //         },
    //     ],
    // },
]
