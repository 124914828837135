<script>
import HeadsetGroupsTableList from '@components/Headsets/Groups/HeadsetGroupsTableList.vue'
export default {
    components: { HeadsetGroupsTableList },
}
</script>

<template>
    <headset-groups-table-list />
</template>

<style scoped lang="scss">

</style>