<script>
    export default {
        props: ['value', 'isVertical','compact'],
        computed:{
          label(){
            return Math.round(this.value) + '%';
          }
        }
    }
</script>
<template>
    <div
        v-if="!Number.isNaN(Math.round(value))"
        :class="{ 'battery-vertical': isVertical }"
        class="battery-level-container"
    >
        <ra-typo v-tablet v-if="!compact">{{ label }}</ra-typo>
      <ra-typo span :tooltip="compact ? label : null ">
        <div class="battery-ico" :class="[compact && 'battery-ico-compact']">
            <div
                :style="{
                    width: Math.round(value) + '%',
                    opacity: Math.max(0.5, value / 100),
                }"
                class="battery-fill"
            ></div>
        </div>
      </ra-typo>
        <ra-typo v-web v-if="!compact">{{ label }}</ra-typo>
    </div>
</template>
<style lang="scss">
    .battery-level-container {
        &.battery-vertical {
            position: absolute;
            bottom: 0.5em;
            left: calc(100% - 7em);
            flex-direction: column-reverse;
            align-items: center;
            gap: 5px;
            .battery-ico {
                transform: rotate(-90deg);
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        display: flex;
        align-items: center;
        font-size: smaller;
        justify-content: flex-end;

        .ra-typography {
            font-size: 10px;
            font-weight: 600;
        }

        .battery-ico {
            --height: 15px;
            --width: 25px;
            --coef: 0.1;
            width: var(--width);
            height: var(--height);
            border: 2px solid var(--primary);
            border-radius: 3px;
            margin-left: 5px;
            margin-right: 5px;
            display: flex;
            transform: scale(0.8);

            padding: calc(var(--height) * var(--coef)) 0;
            align-items: center;

            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: 5px;
                height: 10px;
                border: 2px solid var(--primary);
                border-radius: 5px;
                border-left: none;
                left: calc(100% + 1px);
                top: 50%;
                transform: translateY(-50%);
            }

            .battery-fill {
                display: block;
                height: 100%;
                margin: 0 calc(var(--height) * var(--coef));
                border-radius: 1px;
                background-color: var(--primary);
            }

            &-compact{
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
        }
    }
</style>
