<script>

export default {
    data(){
        return {
            edit:false,
            notes : ""
        }
    },
    methods: {
        toggleEdit() {
            this.edit = !this.edit
        }
    }
}
</script>

<template>
    <ra-stack padding="0,6" gap="4">
        <ra-card>
            <template #title>
                {{$t('headsets.properties.note.label')}}
            </template>
            <template #right>
                <ra-icon weight="bold" class="clickable" @click="toggleEdit" v-if="!edit">fa-edit</ra-icon>
                <ra-typo span underline class="clickable" @click="toggleEdit" v-else>{{$t('generics.cancel')}}</ra-typo>
            </template>
            <ra-stack padding="3">
                <template  v-if="!edit">
                    <ra-typo>
                        {{notes}}
                    </ra-typo>
                </template>

                <template v-else>
                    <ra-input name="notes">
                        <v-textarea v-model="notes" hide-details density="comfortable" variant="outlined" />
                    </ra-input>
                    <ra-stack direction="horizontal">
                        <ra-spacer/>
                        <v-btn variant="flat" color="primary-gradient">
                            {{$t('generics.save')}}
                        </v-btn>
                    </ra-stack>
                </template>


            </ra-stack>

        </ra-card>
    </ra-stack>
</template>

<style scoped lang="scss">

</style>