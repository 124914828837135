import { MyUsersRoutesNames } from '@routes/Former/MyUsers.name.js'
import {
    adminFormersRoutesMeta as adminMeta,
    formersRoutesMeta as meta,
} from '@routes/Former/meta.js'

export default [
    {
        path: '/my-users',
        component: () => import('@pages/My-Users/MyUsersShowPage.vue'),
        children: [
            {
                path: '',
                name: MyUsersRoutesNames.index,
                redirect: { name: MyUsersRoutesNames.formers },
                meta,
            },
            {
                path: 'formers',
                name: MyUsersRoutesNames.formers,
                meta: adminMeta,
                component: () =>
                    import('@components/Formers/FormerTableList.vue'),
                children: [
                    {
                        path: ':former_id',
                        children: [
                            {
                                path: 'profile',
                                name: MyUsersRoutesNames.formerProfile,
                                component: () =>
                                    import(
                                        '@components/Formers/FormerInfoDialog.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'learners',
                name: MyUsersRoutesNames.learners,
                component: () =>
                    import('@components/Learners/LearnerTableList.vue'),
                meta,
                children: [
                    {
                        path: ':learner_id',
                        children: [
                            {
                                path: '',
                                name: MyUsersRoutesNames.learnerProfile,
                                component: () =>
                                    import(
                                        '@components/Learners/LearnerInfoDialog.vue'
                                    ),
                                children: [
                                    {
                                        path: 'profile',
                                        name: MyUsersRoutesNames.infoTab,
                                        component: () =>
                                            import(
                                                '@components/Learners/Tabs/LearnerInfoTab.vue'
                                            ),
                                    },
                                    {
                                        path: 'formations',
                                        name: MyUsersRoutesNames.formationTab,
                                        component: () =>
                                            import(
                                                '@components/Learners/Tabs/LearnerFormationsTab.vue'
                                            ),
                                    },
                                    {
                                        path: 'sessions',
                                        name: MyUsersRoutesNames.sessionTab,
                                        component: () =>
                                            import(
                                                '@components/Learners/Tabs/LearnerSessionTab.vue'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'guest',
                name: MyUsersRoutesNames.guest,
                component: () =>
                    import('@components/Guests/GuestTableList.vue'),
                meta,
            },
        ],
    },
]
