<script>

import ApplicationLayout from "@layouts/global/ApplicationLayout.vue";
import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'
export default {
  components: { ApplicationLayout },
  data(){
    return {
      currentTab: 0,
    }
  },
  computed:{
      tabs(){
          return [
              {
                  name: this.$tc("headsets._name", 2),
                  route: {
                      name: HeadsetRoutesNames.index,
                      query: this.$route.query,
                  },
              },
              {
                  name: this.$tc('groups._name', 2),
                  route: {
                      name: HeadsetRoutesNames.groups,
                      query: this.$route.query,
                  },
              },
          ];
      }
  }
}
</script>

<template>
<ApplicationLayout>
  <ra-header-with-tabs
      v-model="currentTab"
      :tabs="tabs"
      :title="$tc('generics.management_of') + ' ' + $tc('headsets._name', 2)"
  >
  </ra-header-with-tabs>
</ApplicationLayout>
</template>

<style scoped lang="scss">

</style>