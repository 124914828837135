<script>
    import StreamPlayerVideo from '@components/Headsets/Pilotage/Players/StreamPlayerVideo.vue'

    export default {
        components: { StreamPlayerVideo },
        props: {
            streamedHeadsets: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fullScreen: false,
                pinned: null,
            }
        },
        computed: {
            streamCount() {
                return this.streamedHeadsets.length
            },
            notPinnedStream() {
                return this.streamedHeadsets.filter((h) => !h.is(this.pinned))
            },

            nbStreamGridCount() {
                if (this.streamCount > 2) return 4
                else return this.streamCount
            },

            streamGridElements() {
                let streams = []
                for (let i = 0; i < this.nbStreamGridCount; i++) {
                    streams.push(this.streamedHeadsets[i] || null)
                }
                return streams
            },
        },
        methods: {
            pinStream(headset) {
                if (this.pinned && this.pinned.is(headset)) {
                    this.pinned = null
                } else {
                    this.pinned = headset
                }
            },
        },
    }
</script>

<template>
    <ra-stack
        ref="stream-player-container"
        height="100%"
        overflow="hidden"
    >
        <template v-if="pinned">
            <!-- not pinned stream -->
            <ra-stack
                ref="not-pinned-stream"
                direction="horizontal"
                class="not-pinned-stream-list"
                distribution="side-spacing"
            >
                <stream-player-video
                    v-for="headset in notPinnedStream"
                    :key="'stream-player-video-' + headset.serialNumber"
                    :headset="headset"
                    :compact="true"
                    @toggle-pinned="pinStream(headset)"
                />
            </ra-stack>
            <!-- not pinned stream -->
            <ra-box class="pinned-stream">
                <stream-player-video
                    :headset="pinned"
                    @toggle-pinned="pinStream(pinned)"
                />
            </ra-box>
        </template>
        <template v-else>
            <ra-box
                class="all-stream-grid"
                :class="{
                    'two-streams': streamCount === 2,
                    'four-streams': streamCount >= 3,
                    'one-stream': streamCount === 1,
                }"
            >
                <stream-player-video
                    v-for="headset in streamGridElements"
                    :key="'stream-player-video-' + headset.serialNumber"
                    :headset="headset"
                    @toggle-pinned="pinStream(headset)"
                />
            </ra-box>
        </template>
    </ra-stack>
</template>

<style scoped lang="scss">
    .not-pinned-stream-list {
        height: 130px;
    }
    .pinned-stream {
        height: calc(100% - 130px - 12px);
    }

    .all-stream-grid {
        padding: 10vh 0;
        height: 100%;
        display: grid;
        gap: 1em;

        &.one-stream {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }
        &.two-streams {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
        }
        &.four-streams {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }
</style>
