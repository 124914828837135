<script>
    import '@realityteam/reality-academy-ui-kit/dist/style.css'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { useLanguageStore } from '@store/lang'
    import GlobalDialog from '@components/global/Dialogs/GlobalDialog.vue'
    import { mapActions, mapState } from 'pinia'
    import { useGlobalSnackBar } from './store/globalSnakbar.js'
    import { useGlobalEnv } from '@store/globalEnv.js'
    import { useAppStore } from './store/app.js'
    import { PWACacheProvider } from '@providers/PWACacheProvider.js'
    import { PWAUpdateUtils } from '@utils/PWAUpdateUtils.js'

    export default {
        components: { GlobalDialog },
        mixins: [mixins.RaAlertMixin],
        data() {
            return {
                ws: null,
                assetsLoader: {
                    assetsCount: 0,
                    assetsSize: 0,
                    loading: true,
                },
                headsets: [],
                pwaUpdateUtils: null,
            }
        },
        mounted() {
            window.$app = this
            window.$alert = this.showSnackBar
            this.initApp()
        },

        computed: {
            ...mapState(useGlobalSnackBar, ['snackBar']),
            ...mapState(useLanguageStore, ['lang']),
            ...mapState(useGlobalEnv, ['globalEnv']),
            ...mapState(useAppStore, ['app_env', 'isTablet', 'hasInternet']),
        },

        methods: {
            ...mapActions(useGlobalSnackBar, ['showSnackBar', 'hideSnackBar']),
            async initApp() {
                this.pwaUpdateUtils = new PWAUpdateUtils(
                    this.showSnackBar,
                    this.hideSnackBar,
                    this.$t
                )
                this.pwaUpdateUtils.listenForUpdate()
                if (this.isTablet && this.hasInternet) {
                    try {
                        const { data: assets } =
                            await PWACacheProvider.getAssets().call()
                        this.assetsLoader.assetsSize = assets.length
                        let tasks = [
                            PWACacheProvider.loadAssets(assets, (count) => {
                                this.assetsLoader.assetsCount = count
                            }),
                            new Promise((resolve) => setTimeout(resolve, 2000)),
                        ]
                        await Promise.all(tasks)
                    } catch (e) {
                        console.error(e)
                    }
                }
                this.assetsLoader.loading = false
            },
        },

        watch: {
            snackBar(v) {
                if (v) this.alert(v.html, v?.color, v?.duration)
                if (v == null) this.alert(null)
            },
        },
    }
</script>
<template>
    <div>
        <router-view :key="lang + globalEnv"></router-view>
        <global-dialog />
        <ra-alert
            ref="alert"
            :class="snackBar?.class"
            :closable="snackBar?.closable"
            :actions="snackBar?.actions"
            :duration="5"
            data-test="globalAlert"
            global
        />
        <div
            v-if="!app_env.VITE_APP_ENV.startsWith('prod')"
            class="env-mode"
        >
            <ra-typo span>
                Environement : {{ app_env.VITE_APP_ENV }} | api URL :
                {{ app_env.VITE_API_URL }}
            </ra-typo>
        </div>
    </div>
    <div
        class="loader-tablet"
        :class="[
            assetsLoader.loading && isTablet ? 'loader-tablet--display' : '',
        ]"
    >
        <v-progress-linear
            indeterminate
            color="primary-gradient"
            height="5px"
        />
        <ra-box
            height="100vh"
            centered-content
        >
            <ra-stack
                width="auto"
                alignement="center"
                gap="8"
            >
                <img
                    height="30"
                    alt="logo"
                    src="@assets/images/logos/ra_link_logo_full.png"
                />

                <ra-typo>
                    {{ $t('generics.loading') }}
                    <ra-typo
                        span
                        semi
                        v-if="assetsLoader.assetsSize"
                    >
                        {{
                            Math.round(
                                (assetsLoader.assetsCount * 100) /
                                    assetsLoader.assetsSize
                            )
                        }}
                        %
                    </ra-typo>
                </ra-typo>
                <v-progress-circular
                    color="primary"
                    :indeterminate="
                        !assetsLoader.assetsSize ||
                        Math.round(
                            (assetsLoader.assetsCount * 100) /
                                assetsLoader.assetsSize
                        ) === 100
                    "
                    :model-value="
                        Math.round(
                            (assetsLoader.assetsCount * 100) /
                                assetsLoader.assetsSize
                        )
                    "
                ></v-progress-circular>
            </ra-stack>
        </ra-box>
    </div>
</template>
<style lang="scss">
    @import '@sass/main';
    .env-mode {
        position: fixed;
        width: 100%;
        padding: 0.5em;
        bottom: 0em;
        z-index: 10000;
        opacity: 0.25;
        pointer-events: none;
    }
    .loader-tablet {
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s;
        display: block;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ececf7;
        z-index: 10000;
        &--display {
            opacity: 1;
        }
    }
</style>
