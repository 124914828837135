import ApiClient from '@api'

export class MonitoringLogger {
    static originalConsole = {}
    static instance = null
    static apibatchLogEndpoint = '/monitoring/logs'
    static loggingCooldown = 1000 * 60
    static waiterCooldown = 500

    constructor() {
        this.apiClient = new ApiClient()
        this.logs = new LogBag()
        this.loop = null

        this.start()
        this._waiter = null
    }

    /**
     * @returns {MonitoringLogger}
     */
    static getInstance() {
        if (MonitoringLogger.instance === null)
            MonitoringLogger.instance = new MonitoringLogger()
        return MonitoringLogger.instance
    }

    start() {
        this.loop = setInterval(() => {
            this.sendLogs()
        }, MonitoringLogger.loggingCooldown)
    }
    stop() {
        clearInterval(this.loop)
    }

    setUp(console) {
        // let functions = ['log', 'error']
        // functions.forEach((functionName) => {
        //     let originalFunction = console[functionName]
        //     MonitoringLogger.originalConsole[functionName] = originalFunction
        //     console[functionName] = (...args) => {
        //         originalFunction(...args)
        //         this[functionName](...args)
        //     }
        // })
        //
        // console.save = () => {
        //     this.sendLogs()
        // }
    }

    log(...args) {
        for (let arg of args) this.logs.addLog(new Log(LogBag.INFO_LEVEL, arg))
    }
    warning(...args) {
        for (let arg of args)
            this.logs.addLog(new Log(LogBag.WARNING_LEVEL, arg))
    }
    error(...args) {
        for (let arg of args) this.logs.addLog(new Log(LogBag.ERROR_LEVEL, arg))
        if (this._waiter) clearTimeout(this._waiter)
        this._waiter = setTimeout(() => {
            this.sendLogs(false)
        }, MonitoringLogger.waiterCooldown)
    }

    sendLogs(makeLog = true) {
        let logsToSent = this.logs.errors.toSend
        if (logsToSent.length === 0) return
        logsToSent.hasBeenSent = true
        return this.apiClient
            .post(MonitoringLogger.apibatchLogEndpoint, {
                logs: logsToSent,
            })
            .call()
            .then()
            .catch((error) => {
                logsToSent.hasBeenSent = false
                this.originalConsole.error(error)
                this.stop()
            })
    }
}

class LogBag extends Array {
    static INFO_LEVEL = 'INFO'
    static WARNING_LEVEL = 'WARNING'
    static ERROR_LEVEL = 'ERROR'

    constructor() {
        super()
    }

    get errors() {
        return this.filter((log) => log.level === LogBag.ERROR_LEVEL)
    }
    get infos() {
        return this.filter((log) => log.level === LogBag.INFO_LEVEL)
    }
    get warnings() {
        return this.filter((log) => log.level === LogBag.WARNING_LEVEL)
    }
    get toSend() {
        return this.filter((log) => !log.hasBeenSent)
    }
    set hasBeenSent(value) {
        this.forEach((log) => (log.hasBeenSent = value))
    }

    /**
     *
     * @param {Log} log
     */
    addLog(log) {
        if (
            this.some(
                (l) =>
                    l.base64 === log.base64 && log.level == LogBag.ERROR_LEVEL
            )
        )
            return
        if (log) this.unshift(log)
    }
    empty() {
        this.splice(0, this.length)
    }
}

class Log {
    constructor(level, message, context = {}) {
        if (message === undefined) return null
        this.date = new Date()
        this.from = 'WEB'
        this.level = level
        this.context = { ...this._context, ...context }
        this.hasBeenSent = false
        this.setMessage(message)
    }
    get base64() {
        let string = this.level + this.message
        if (this?.context?.stack) string += this.context.stack
        if (this?._context?.url) string += this._context.url
        return btoa(string)
    }
    get _context() {
        return {
            url: window.location.href,
        }
    }
    setMessage(message) {
        if (message instanceof Error) {
            this.message = message.message
            this.context.stack = message.stack
            if ('context' in message)
                this.context = { ...this.context, ...message.context }
        } else if (typeof message == 'object') {
            let json = ''
            try {
                json = JSON.stringify(message, getCircularReplacer(this.level == LogBag.ERROR_LEVEL ? 20 : 1))
            } catch (e) {
                json = 'Error while parsing JSON'
            }
            this.message = json.length > 190 ? 'Message JSON' : json
            if (json.length > 190) this.context.json = JSON.parse(json)
        } else this.message = message
    }
}
function getCircularReplacer(maxDepth = 20) {
    const ancestors = []
    let o = 0
    return function (key, value) {
        if (typeof value !== 'object' || value === null) {
            return value
        }
        let i = 0
        while (ancestors.length > 0 && ancestors.at(-1) !== this) {
            ancestors.pop()
            i++
            if(i > maxDepth) break
        }
        if (ancestors.includes(value)) {
            return '[Circular]'
        }
        ancestors.push(value)
        if(o > maxDepth) return '[Too deep]'
        return value
    }
}

export class MonitoringLogError extends Error {
    constructor(...args) {
        super(...args)
        this._context = {
            url: window.location.href,
        }
        this.context = {}
    }

    setContext(context) {
        this.context = {
            ...this._context,
            ...this.context,
            ...context,
        }
        return this
    }
}
