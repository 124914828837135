<script setup>

</script>

<template>
  <ra-base-dialog padding="0" v-bind="$args">
    <template #title>
      <slot name="title"></slot>
    </template>
    <ra-stack style="height: 100%" gap="0" distribution="fill">
      <ra-box padding="4" bg="white" class="header-right-expand-panel--header">
        <slot name="header"></slot>
      </ra-box>
      <ra-box padding="4" bg="background" class="header-right-expand-panel--body">
        <slot></slot>
      </ra-box>
    </ra-stack>
  </ra-base-dialog>
</template>

<style scoped lang="scss">
.header-right-expand-panel--header{
  border-bottom: 1px solid var(--primary-15-alpha);
}
</style>

<style lang="scss">
.header-right-expand-panel--body{
  .v-slide-group{
    background: white !important;
  }
}
</style>