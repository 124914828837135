import MyHeadsetIndexPage from '@pages/My-Headsets/MyHeadsetIndexPage.vue'
import MyHeadsetDrive from '@pages/My-Headsets/Drive.vue'
import MyHeadsetStartSession from '@pages/My-Headsets/Start-Session.vue'
import { HeadsetRoutesNames } from '@core/router/routes/generators/Headset.names.js'
import MyHeadsetShow from "@pages/My-Headsets/HeadsetShowPage.vue"
import HeadsetSettingsTab from "@components/Headsets/Tabs/HeadsetSettingsTab.vue";
import HeadsetFormationLibrairyTab from '@components/Headsets/Tabs/HeadsetFormationLibrairyTab.vue'
import HeadsetGroupTab from '@components/Headsets/Tabs/HeadsetGroupTab.vue'
import HeadsetInformationsTab from '@components/Headsets/Tabs/HeadsetInformationsTab.vue'
import HeadsetNotesTabs from '@components/Headsets/Tabs/HeadsetNotesTabs.vue'
import TabletHeadsetListPage from "@pages/My-Headsets/TabletHeadsetListPage.vue";
import MyHeadsetsListPage from "@pages/My-Headsets/MyHeadsetsListPage.vue";
import MyHeadsetsGroupsPage from "@pages/My-Headsets/MyHeadsetsGroupsPage.vue";

export const headsetsRoutes = (
    namePrefix = '',
    meta = {},
    entry = 'headsets'
) => {
    function publicMeta() {
        if (entry == '/my-headsets') return {}
        else return meta
    }

    return [
        {
            path: entry,
            children: [
                {
                    path: '',
                    meta: {
                        //authorize no connection to access this route (tablet offline mode)
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: MyHeadsetIndexPage,
                    beforeEnter: (to, from, next) => {
                        if(window.IS_TABLET) {
                            next({name: namePrefix + HeadsetRoutesNames.tablet})
                        }else {
                            next()
                        }
                    },
                    redirect: { name: namePrefix + HeadsetRoutesNames.index },
                    children: [
                        {
                            path: 'list',
                            name: namePrefix + HeadsetRoutesNames.index,
                            component: MyHeadsetsListPage,
                            children:[
                                {
                                    path: ':serialNumber',
                                    name: namePrefix + HeadsetRoutesNames.show,
                                    meta,
                                    component: MyHeadsetShow,
                                    redirect: { name: namePrefix + HeadsetRoutesNames.settings },
                                    children: [
                                        {
                                            path:'settings',
                                            name: namePrefix + HeadsetRoutesNames.settings,
                                            component: HeadsetSettingsTab
                                        },
                                        {
                                            path:'library',
                                            name: namePrefix + HeadsetRoutesNames.library,
                                            component: HeadsetFormationLibrairyTab
                                        },
                                        {
                                            path:'group',
                                            name: namePrefix + HeadsetRoutesNames.group,
                                            component: HeadsetGroupTab
                                        },
                                        {
                                            path:'infos',
                                            name: namePrefix + HeadsetRoutesNames.infos,
                                            component: HeadsetInformationsTab
                                        },
                                        {
                                            path:'notes',
                                            name: namePrefix + HeadsetRoutesNames.notes,
                                            component: HeadsetNotesTabs
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path: "groups",
                            name: namePrefix + HeadsetRoutesNames.groups,
                            component: MyHeadsetsGroupsPage,
                        }
                    ]

                },

                {
                    path:'tablet',
                    name: namePrefix + HeadsetRoutesNames.tablet,
                    meta: {
                        //authorize no connection to access this route (tablet offline mode)
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: TabletHeadsetListPage,
                    beforeEnter: (to, from, next) => {
                        if(!window.IS_TABLET) {
                            next({name: namePrefix + HeadsetRoutesNames.index})
                        }else {
                            next()
                        }
                    }
                },
                {
                    path: 'start-session',
                    name: namePrefix + HeadsetRoutesNames.startSession,
                    meta: {
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: MyHeadsetStartSession,
                },
                {
                    path: 'drive',
                    name: namePrefix + HeadsetRoutesNames.drive,
                    meta: {
                        allowTabletApp: entry == '/my-headsets',
                        ...publicMeta(),
                    },
                    component: MyHeadsetDrive,
                },
            ],
        },
    ]
}
