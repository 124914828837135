export default {
    theme: {
        themes: {
            RA_UI_KIT_DEFAULT_THEME: {
                colors: {

                    'background':'#ececf7',
                    'on-background':'#090954',

                    'tablet-light': '#f3f3f8',
                    'on-tablet-light': '#090954',

                    'green': '#249163',

                    'vr-purple': "#281c91",

                    'grey-900': "#090B0E",
                    'grey-700':'#1C212A',
                    'grey-100': "#D5DBE3",
                    'grey-50': "#F1F3F6"

                },
            },
        },
    },
    locale: {
        fallback: 'fr',
        messages: {
            fr: {
                input:{
                    clear: 'Effacer',
                },
                badge: 'Badge',
                open: 'Ouvrir',
                close: 'Fermer',
                dataIterator: {
                    noResultsText: 'Aucun enregistrement correspondant trouvé',
                    loadingText: `Chargement de l'élément...`,
                },
                dataTable: {
                    itemsPerPageText: 'Lignes par page :',
                    ariaLabel: {
                        sortDescending: 'Tri décroissant.',
                        sortAscending: 'Tri croissant.',
                        sortNone: 'Non trié.',
                        activateNone: 'Activer pour supprimer le tri.',
                        activateDescending:
                            'Activer pour trier par ordre décroissant.',
                        activateAscending:
                            'Activer pour trier par ordre croissant.',
                    },
                    sortBy: 'Trier par',
                },
                dataFooter: {
                    itemsPerPageText: 'Élements par page :',
                    itemsPerPageAll: 'Tous',
                    nextPage: 'Page suivante',
                    prevPage: 'Page précédente',
                    firstPage: 'Première page',
                    lastPage: 'Dernière page',
                    pageText: '{0}-{1} de {2}',
                },
                datePicker: {
                    title: 'Sélectionner une date',
                    itemsSelected: '{0} sélectionné(s)',
                    nextMonthAriaLabel: 'Le mois prochain',
                    nextYearAriaLabel: `L'année prochaine`,
                    prevMonthAriaLabel: 'Le mois précédent',
                    prevYearAriaLabel: 'Année précédente',
                },
                noDataText: 'Aucune donnée disponible',
                carousel: {
                    prev: 'Visuel précédent',
                    next: 'Visuel suivant',
                    ariaLabel: {
                        delimiter: 'Diapositive {0} de {1}',
                    },
                },
                calendar: {
                    moreEvents: '{0} de plus',
                },
                fileInput: {
                    counter: '{0} fichier(s)',
                    counterSize: '{0} fichier(s) ({1} au total)',
                },
                timePicker: {
                    am: 'AM',
                    pm: 'PM',
                },
                pagination: {
                    ariaLabel: {
                        root: 'Navigation de pagination',
                        wrapper: 'Navigation de pagination',
                        next: 'Page suivante',
                        previous: 'Page précédente',
                        page: 'Aller à la page {0}',
                        currentPage: 'Page actuelle, Page {0}',
                    },
                },
                rating: {
                    ariaLabel: {
                        icon: 'Rating {0} of {1}',
                    },
                },
            },
            en: {
                badge: 'Badge',
                open: 'Open',
                close: 'Close',
                dataIterator: {
                    noResultsText: 'No matching records found',
                    loadingText: 'Loading item...',
                },
                dataTable: {
                    itemsPerPageText: 'Lines per page:',
                    ariaLabel: {
                        sortDescending: 'Sort descending.',
                        sortAscending: 'Sort ascending.',
                        sortNone: 'Unsorted.',
                        activateNone: 'Enable to remove sorting.',
                        activateDescending:
                            'Enable to sort in descending order.',
                        activateAscending: 'Enable to sort in ascending order.',
                    },
                    sortBy: 'Sort by ',
                },
                dataFooter: {
                    itemsPerPageText: 'Items per Page:',
                    itemsPerPageAll: 'All',
                    nextPage: 'Next Page',
                    prevPage: 'Previous Page',
                    firstPage: 'First Page',
                    lastPage: 'Last Page',
                    pageText: '{0}-{1} of {2}',
                },
                datePicker: {
                    title: 'Date Picker',
                    itemsSelected: '{0} selected',
                    nextMonthAriaLabel: 'Next month',
                    nextYearAriaLabel: 'Next year',
                    prevMonthAriaLabel: 'Previous month',
                    prevYearAriaLabel: 'Previous year',
                },
                noDataText: 'No data available',
                carousel: {
                    prev: 'Previous visual',
                    next: 'Next visual',
                    ariaLabel: {
                        delimiter: 'Slide {0} of {1}',
                    },
                },
                calendar: {
                    moreEvents: '{0} more',
                },
                fileInput: {
                    counter: '{0} file(s)',
                    counterSize: '{0} file(s) ({1} total)',
                },
                timePicker: {
                    am: 'AM',
                    pm: 'PM',
                },
                pagination: {
                    ariaLabel: {
                        root: 'Pagination Navigation',
                        wrapper: 'Paging Navigation',
                        next: 'Next Page',
                        previous: 'Previous Page',
                        page: 'Go to Page {0}',
                        currentPage: 'Current Page, Page {0}',
                    },
                },
                rating: {
                    ariaLabel: {
                        icon: 'Rating {0} of {1}',
                    },
                },
            },
        },
        locale: localStorage.getItem('lang') ?? 'fr',
    },
}
