<script>
    import { PaginatedCollection } from '@libs/Collections/PaginatedCollection.js'

    export default {
        data() {
            return {
                selected: [],
                items: new PaginatedCollection(),
            }
        },
        computed: {
            groupedActions() {
                return []
            },
            itemActions() {
                return []
            },
            headers() {
                return []
            },
        },

        methods: {
            handleFetch() {},
            handleClick() {},
        },
    }
</script>

<template>
    <div>
        <ra-alert></ra-alert>
        <ra-card
            :header="{
                bg: 'primary-gradient',
            }"
        >
            <template #title>
                {{
                    $tc('groups._table_list', Math.max(1, items.total), {
                        count: items.total,
                    })
                }}
            </template>
            <ra-table
                v-model="selected"
                v-model:items-per-page="items.perPage"
                :grouped-actions="groupedActions"
                :headers="headers"
                :item-actions="itemActions"
                :items="items"
                :items-length="items.total"
                :loading="items.loading"
                :no-data-text="$t('groups._no_group')"
                v-bind="$attrs"
                @update:options="handleFetch($event)"
                @click:row="handleClick"
            >
            </ra-table>
        </ra-card>
    </div>
</template>

<style scoped lang="scss"></style>
