<script>
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import { RTCConnectionStatus } from '@libs/MDM/core/RTCConnection.js'

    export default {
        name: 'PilotageAsideHeadsetList',
        components: { ConnectedStatus, BatteryLevel },
        props: {
            headsetsAppDriven: {
                type: Array,
                default: () => [],
            },
            streamedHeadsets: {
                type: Array,
                default: () => [],
            },
        },
        emits: ['click', 'update:streamedHeadsets'],
        data() {
            return {
                preventChange: false,
                selectedIndex: [],
                cooldown: {
                    active: false,
                    duration: 600,
                },
            }
        },
        computed: {
            isBlocked() {
                return this.cooldown.active || !this.canSelect()
            },
            value: {
                get() {
                    return this.streamedHeadsets
                },
                set(value) {
                    if (value.length > 4) {
                        value.shift()
                    }
                    this.$emit('update:streamedHeadsets', value)
                },
            },
        },
        methods: {
            canSelect() {
                const hasConnecting = this.value.some(
                    (headset) =>
                        headset.emitingRTCConnection.status ===
                            RTCConnectionStatus.CONNECTING ||
                        headset.receivingRTCConnection.status ===
                            RTCConnectionStatus.CONNECTING
                )
                return !hasConnecting
            },

            handleClick(headset) {
                if (this.isBlocked) return

                this.cooldown.active = true

                if (this.isSelected(headset)) {
                    this.value = this.value.filter(
                        (h) => h.serialNumber !== headset.serialNumber
                    )
                } else if (!headset.isIdle) {
                    this.value = [...this.value, headset]
                } else {
                    this.cooldown.active = false
                }

                setTimeout(() => {
                    this.cooldown.active = false
                }, this.cooldown.duration)
            },

            isSelected(headset) {
                return this.streamedHeadsets.some(
                    (h) => h.serialNumber === headset.serialNumber
                )
            },
        },
    }
</script>

<template>
    <ra-stack gap="6">
        <ra-typo
            v-tablet
            bold
            padding=", 5"
            uppercase
            variant="title"
        >
            {{ $t('headsets.pilotage.headsets_list.title') }}
        </ra-typo>

        <ra-stack
            :style="{ opacity: isBlocked ? 0.5 : 1 }"
            gap="0"
        >
            <v-progress-linear
                :style="{ opacity: isBlocked ? 1 : 0 }"
                class="linear-progress-pilotage"
                indeterminate
            />
            <div
                v-for="headset in headsetsAppDriven"
                :key="'headset-' + headset.serialNumber"
                v-ripple="{
                    class: 'text-primary',
                }"
                class="headset-pilotage-aside-list-item-container"
                :style="{
                    opacity: !isSelected(headset) && headset.isIdle ? 0.5 : 1,
                }"
                @click="handleClick(headset)"
            >
                <ra-stack
                    class="headset-pilotage-aside-list-item position-relative"
                    :class="{
                        'headset-pilotage-aside-list-item--selected':
                            isSelected(headset),
                    }"
                    direction="horizontal"
                    alignement="center"
                    gap="6"
                >
                    <img
                        :src="headset.image"
                        class="object-fit-contain"
                        alt="Headset Image"
                    />
                    <ra-stack gap="1">
                        <ra-stack direction="horizontal">
                            <tablet>
                                <ra-typo
                                    bold
                                    max-width="100%"
                                    no-word-wrap
                                >
                                    {{
                                        headset.name || $t('headsets._unknown')
                                    }}
                                </ra-typo>
                            </tablet>
                            <web>
                                <ra-typo
                                    bold
                                    max-width="70%"
                                    no-word-wrap
                                >
                                    {{
                                        headset.name || $t('headsets._unknown')
                                    }}
                                </ra-typo>
                            </web>
                            <v-chip
                                v-if="headset.stateChip"
                                size="x-small"
                            >
                                {{ $t(headset.stateChip) }}
                            </v-chip>
                        </ra-stack>

                        <ra-stack
                            alignement="center"
                            direction="horizontal"
                            gap="5"
                        >
                            <connected-status :headset="headset" />
                            <web>
                                <battery-level
                                    :is-vertical="true"
                                    :value="headset.state.batteryLevel"
                                />
                            </web>
                            <tablet>
                                <battery-level
                                    :value="headset.state.batteryLevel"
                                />
                            </tablet>
                        </ra-stack>
                    </ra-stack>
                    <v-icon
                        :color="isSelected(headset) ? 'primary' : 'transparent'"
                        icon="mdi-check"
                        size="large"
                    />
                </ra-stack>
            </div>
        </ra-stack>
    </ra-stack>
    <ra-btn
        v-if="streamedHeadsets"
        bg="primary"
        margin="4, 5"
        @click="$emit('click')"
    >
        {{ $t('headsets.pilotage.stop_stream') }}
    </ra-btn>
</template>
<style lang="scss">
    .headset-pilotage-aside-list-item {
        padding: 10px 24px;
        &--selected {
            background: rgba(9, 9, 84, 0.2);
        }
        img {
            width: 50px;
        }
    }

    .headset-pilotage-aside-list-item-container
        + .headset-pilotage-aside-list-item-container {
        border-top: 1px solid rgba(9, 9, 84, 0.3);
    }
</style>
