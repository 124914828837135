<script>
    import { Collection } from '@libs/Collections/Collection.js'
    import { GroupProvider } from '@providers'

    export const GROUP_CATEGORY = {
        USER: 1,
        HEADSET: 2,
        LEARNER: 3,
    }
    export default {
        name: 'GroupSelector',
        props: {
            category: {
                type: Number,
                default: GROUP_CATEGORY.USER,
            },
            company: {
                type: Number,
                default: null,
            },
            modelValue: {
                default: null,
                type: Number,
            },
        },

        emits: ['update:modelValue', 'groups:loaded'],
        data() {
            return {
                groups: new Collection(),
            }
        },
        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(val) {
                    this.$emit('update:modelValue', val)
                },
            },
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.groups.setFetcher(() =>
                    GroupProvider.findAll({
                        company_id: this.company,
                        category: this.category,
                    })
                )

                this.groups.fetch().then(() => {
                    this.$emit('groups:loaded', Array.from(this.groups))
                })
            },
        },
    }
</script>

<template>
    <ra-input name="group">
        <v-select
            v-model="value"
            density="compact"
            :items="groups"
            :loading="groups.loading"
            multiple
            variant="outlined"
            :label="$t('groups._name')"
            item-title="name"
            item-value="id"
            v-bind="$attrs"
        >
            <slot></slot>
        </v-select>
    </ra-input>
</template>

<style scoped lang="scss"></style>
