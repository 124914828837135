export const HeadsetRoutesNames = {
    tablet: 'headsets.tablet',
    index: 'headsets.index',
    show: 'headsets.show',
    groups: 'headsets.groups',
    settings: 'headsets.settings',
    library: 'headsets.library',
    group: 'headsets.group',
    infos: 'headsets.infos',
    notes:  'headsets.notes',
    //use in tablet (so offline ok)
    startSession: 'headsets.startSession',
    drive: 'headsets.drive',
}
