import { EventEmmiter } from '@utils/EventEmmiter.js'
import { WSType } from './WebsocketClient.js'
import { reactive } from 'vue'
import { RTCEvent, RaPeer, RTCErrors } from './RaPeer.js'

/**
 * Use for the debug mode, give information about the RTCConnection, messages receive, and send.
 */
class MonitoringArray extends Array {
    constructor(...props) {
        super(...props)
        return reactive(this)
    }

    get lastMessages() {
        return [...this].splice(-10)
    }
    push(data) {
        super.push({
            date: new Date(),
            data,
        })
        if (this.length > 100) {
            this.shift()
        }
    }

    merge(labelThis, labelThem, monit) {
        let array = new MonitoringArray()
        const thisLabeled = this.map((item) => ({ ...item, label: labelThis }))
        const themLabeled = monit.map((item) => ({ ...item, label: labelThem }))
        array = array.concat(thisLabeled, themLabeled)
        array.sort((a, b) => a.date - b.date)
        return array
    }
    clear() {
        this.splice(0, this.length)
    }
}

export const RTCConnectionStatus = {
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
    DISCONNECTED: 'disconnected',
    FAILED: 'failed',
}
export class RTCConnection extends EventEmmiter {
    constructor(config, headset) {
        super()
        this.config = config
        this.headset = headset
        this.peer = null

        /**
         * Monitoring of the RTCConnection use by MDMDebug
         */
        this.monitoring = {
            sended: new MonitoringArray(),
            received: new MonitoringArray(),
            errors: new MonitoringArray(),
            streamTracks: [],
        }

        this.active = false
        this.status = RTCConnectionStatus.DISCONNECTED
        this.stream = new MediaStream()

        return reactive(this)
    }

    /**
     *
     * @param {string[]} statusArray
     * @returns {boolean}
     */
    hasStatus(statusArray){
        if(!Array.isArray(statusArray)) statusArray = [statusArray]
        return statusArray.includes(this.status)
    }
    isClosed() {
        return (
            this.status == RTCConnectionStatus.DISCONNECTED &&
            this.peer === null
        )
    }
    connect() {
        this.monitoring.streamTracks = []
        this.monitoring.sended.clear()
        this.monitoring.received.clear()
        this.monitoring.errors.clear()
        this.status = RTCConnectionStatus.CONNECTING
        this.init()
    }
    /**
     * Init RTCConnection
     */
    init() {
        this.peer = new RaPeer(this.config, this.headset)

        this.peer.on(RTCEvent.ICE, this.handleIceCandidate.bind(this))
        this.peer.on(RTCEvent.OFFER, this.handleOffer.bind(this))
        this.peer.on(RTCEvent.CONNECT, this.handleConnect.bind(this))
        this.peer.on(RTCEvent.DATA, this.handleData.bind(this))
        this.peer.on(RTCEvent.TRACK, this.handleTrack.bind(this))
        this.peer.on(RTCEvent.REMOVE_TRACK, this.handleRemoveTrack.bind(this))
        this.peer.on(RTCEvent.ERROR, this.handleError.bind(this))
        this.peer.on(RTCEvent.END, this.handleEnd.bind(this))
    }

    handleOffer(offer) {
        this.WSsend({
            type: WSType.OFFER,
            offer,
            serialNumber: this.headset.serialNumber,
        })
    }

    handleIceCandidate(candidate) {
        if (candidate) {
            this.WSsend({
                type: WSType.ICE,
                candidate,
                serialNumber: this.headset.serialNumber,
            })
        }
    }

    /**
     * Handle connect from peer
     */
    handleConnect() {
        this.active = true
        this.status = RTCConnectionStatus.CONNECTED
        this.emit(RTCEvent.READY)
    }

    /**
     * Handle data from peer
     * @param {Object} data
     */
    handleData(data) {
        this.emit(RTCEvent.DATA, data)
        this.monitoring.received.push(data.toString())
        if (typeof data.toString() === 'string') {
            try {
                data = JSON.parse(data.toString())
                if (data?.type) {
                    this.peer.emit('action:' + data.type, data)
                }
            } catch (err) {
                console.warn(
                    'Message receive is not a valid JSON',
                    data.toString()
                )
            }
        }
    }

    /**
     * Stream from the peer
     * @param {MediaStream} stream
     */
    handleTrack(track) {
        this.monitoring.streamTracks.push(track)
        this.stream.addTrack(track)
        this.emit(RTCEvent.STREAM, this.stream)
    }

    handleRemoveTrack(track) {
        this.stream.removeTrack(track)
        this.emit(RTCEvent.STREAM, this.stream)
    }

    /**
     * @param {RTCErrors} error
     * Handle error from peer connection
     */
    handleError(errorType) {
        const needToClose = [RTCErrors.FAILED, RTCErrors.TIMEOUT]
        if (needToClose.includes(errorType)) this.headset.abortStream()
        this.monitoring.errors.push(errorType)
    }

    /**
     * Handle end from peer connection
     * @param {Object} data
     */
    handleEnd() {
        this.destroy()
        this.active = false
        this.emit(RTCEvent.END)
    }

    WSsend(data) {
        this.headset.WSsend(data)
    }

    send(...params) {
        this.monitoring.sended.push(...params)
        this.peer.send(...params)
    }

    async signal(...params) {
        const res = await this.peer.signal(...params)
        if (!this.peer.initiator && res) {
            this.WSsend({
                type: WSType.ANSWER,
                answer: res,
            })
        }
    }

    destroy() {
        if (this.peer) {
            this.peer.destroy()
            this.peer = null
        }
        this.offAll()
        this.status = RTCConnectionStatus.DISCONNECTED
    }

    addStream(stream) {
        this.monitoring.streamTracks.push(...stream.getTracks())
        this.peer.addStream(stream)
    }

    removeStream(stream) {
        this.monitoring.streamTracks = this.monitoring.streamTracks.filter(
            (t) => !stream.getTracks().includes(t)
        )
        this.peer.removeStream(stream)
    }

    waitForResponse(...args) {
        return this.peer.waitForResponse(...args)
    }
}
