import { reactive } from 'vue'
import { Headset } from './Headset'
import { Collection } from '@libs/Collections/Collection.js'

export class HeadsetManager extends Map {
    constructor() {
        super()
        /**
         * @type {WebSocketClient|null}
         * @private
         */
        this._ws = null
        return reactive(this)
    }

    attachWS(ws) {
        this._ws = ws
    }

    /**
     * Get all headsets
     * @returns {Array<Headset>}
     */
    get all() {
        return Array.from(this.values())
    }

    /**
     * @param {Array<Headset>} array
     * @returns {HeadsetManager}
     */
    from(array) {
        array.forEach((h) => super.set(h.serialNumber, h))
        return this
    }

    /**
     * Add a new headset with the given serial number
     * @param {String} serialNumber
     */
    add({ serialNumber, config }) {
        if (this.has(serialNumber)) throw new Error('Headset already exists')
        const headset = Headset.getInstance(serialNumber, config)
        if (config.state) {
            headset.setState(config.state)
        }
        headset.ws = this._ws
        super.set(serialNumber, headset)
    }

    /**
     * Remove the headset with the given serial number
     * @param {String} serialNumber
     */
    remove(serialNumber) {
        if (!this.has(serialNumber))
            throw new Error(
                'Headset cannot be removed because it does not exist'
            )
        let headset = this.get(serialNumber)
        this.delete(serialNumber)
        headset.ws = null
        headset.destroy()
    }

    /**
     * Get the headset with the given serial number
     * @param {String} serialNumber
     * @returns {Headset}
     */
    get(serialNumber) {
        if (!this.has(serialNumber))
            throw new Error('Headset ' + serialNumber + ' not found')
        return super.get(serialNumber)
    }

    /**
     * Sync the list of headsets with the given serial numbers
     * @param {Array<Object>} serialNumbers
     * @returns {Map<Headset>}
     */
    sync(serialNumbers) {
        let serialNumbersToAdd = new Collection(
            serialNumbers.filter(({ serialNumber }) => !this.has(serialNumber))
        )
        serialNumbersToAdd = serialNumbersToAdd.unique('serialNumber')
        serialNumbersToAdd.forEach(this.add.bind(this))
        let serialNumbersToRemove = Array.from(this.keys()).filter(
            (serialNumber) =>
                !serialNumbers.some((s) => s.serialNumber === serialNumber)
        )

        serialNumbersToRemove.forEach(this.remove.bind(this))

        return this
    }
}
