<script>
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import { Headset } from '@libs/MDM/core/Headset.js'

    export default {
        components: { BatteryLevel },
        props: {
            only: {
                type: String,
                default: null,
            },
            headset: {
                type: Headset,
                required: true,
            },
        },

        computed: {
            controllers() {
                if (this.only) {
                    return this.headset.controllerInformations.filter(
                        (controller) => controller._side === this.only
                    )
                }
                return this.headset.controllerInformations
            },
        },
    }
</script>

<template>
    <ra-stack
        gap="1"
        padding="2,"
    >
        <ra-stack
            v-for="(controller, i) in controllers"
            :key="'controller' + i"
            direction="horizontal"
            distribution="side-spacing"
            gap="4"
            alignement="center"
        >
            <ra-typo
                v-if="controllers.length > 1"
                span
                semi
                :style="{
                    opacity: headset.isConnected ? 1 : 0.5,
                }"
                >{{ controller.side }}</ra-typo
            >
            <ra-typo
                span
                :tooltip="headset.statusLabel"
            >
                <ra-icon
                    style="font-size: 10px"
                    :color="headset.statusColor"
                    >mdi-circle</ra-icon
                >
            </ra-typo>
            <battery-level
                :value="controller.batteryLevel || 100"
                :compact="true"
                :style="{
                    opacity: headset.isConnected ? 1 : 0.5,
                }"
            />
        </ra-stack>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
