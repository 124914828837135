<script>
    export default {
        props:{
            tooltip: {
                type: String,
                default: null
            },
            tooltipOptions: {
                type: Object,
                default: () => ({})
            }
        }
    }
</script>

<template>
    <ra-typo>
        <ra-icon weight="bold" class="help">
            fa-info
        </ra-icon>
        <template #tooltip>
            <slot>{{ tooltip }}</slot>
        </template>
    </ra-typo>
</template>

<style scoped lang="scss">
.help{
    color: var(--primary-60-alpha);
    cursor: pointer;
    :hover{
        color: var(--primary-90-alpha);
    }
}
</style>