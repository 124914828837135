<script>
    import HeadsetPilotageCard from '@components/Headsets/Pilotage/HeadsetPilotageCard.vue'
    import { RTCConnectionStatus } from '@libs/MDM/core/RTCConnection.js'

    export default {
        components: { HeadsetPilotageCard },
        props: {
            headsetsAppDriven: {
                type: Array,
                default: () => [],
            },
            streamedHeadsets: {
                type: Array,
                default: () => [],
            },
        },
        emits: ['update:streamedHeadsets'],

        data() {
            return {
                cooldown: {
                    active: false,
                    duration: 600,
                },
            }
        },

        computed: {
            isBlocked() {
                return this.cooldown.active || !this.canSelect()
            },
            value: {
                get() {
                    return this.streamedHeadsets
                },
                set(value) {
                    if (value.length > 4) {
                        value.shift()
                    }
                    this.$emit('update:streamedHeadsets', value)
                },
            },
        },

        methods: {
            isSelected(headset) {
                return this.streamedHeadsets.some(headset.is.bind(headset))
            },

            canSelect() {
                const hasConnecting = this.value.some(
                    (headset) =>
                        headset.emitingRTCConnection.status ===
                            RTCConnectionStatus.CONNECTING ||
                        headset.receivingRTCConnection.status ===
                            RTCConnectionStatus.CONNECTING
                )
                return !hasConnecting
            },

            handleSelection(headset) {
                if (this.isBlocked) return

                this.cooldown.active = true

                if (this.isSelected(headset)) {
                    this.value = this.value.filter(
                        (h) => h.serialNumber !== headset.serialNumber
                    )
                } else if (!headset.isIdle) {
                    this.value = [...this.value, headset]
                } else {
                    this.cooldown.active = false
                }

                setTimeout(() => {
                    this.cooldown.active = false
                }, this.cooldown.duration)
            },
        },
    }
</script>

<template>
    <ra-stack padding="4">
        <ra-typo
            v-for="headset in headsetsAppDriven"
            :key="'headset-selection-card-' + headset.serialNumber"
            span
        >
            <headset-pilotage-card
                :headset="headset"
                :is-selected="isSelected(headset)"
                @click="() => handleSelection(headset)"
            />
            <template
                v-if="!isSelected(headset) && streamedHeadsets.length === 4"
                #tooltip
            >
                <ra-typo class="text-center"
                    >Vous ne pouvez visualiser que<br />4 casques
                    simultanément.</ra-typo
                >
            </template>
        </ra-typo>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
