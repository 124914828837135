<script>
    import HeadsetsTableList from '@components/Headsets/HeadsetsTableList.vue'
    import CompanyOrMineMixin from '@mixins/CompanyOrMineMixin.js'
    import UserOrMeMixin from '@mixins/UserOrMeMixin.js'
    import { mapActions, mapState } from 'pinia'
    import { useMDMStore } from '@store/mdm.js'

    export default {
        components: { HeadsetsTableList },
        mixins: [CompanyOrMineMixin, UserOrMeMixin],

        data() {
            return {
                headsetToParam: [],
            }
        },
        computed: {
            ...mapState(useMDMStore, ['syncedHeadsets', 'MDM_LOADING']),
        },
        watch: {
            companyID() {
                if (this.companyID) this.fetchCompanyHeadsets(this.companyID)
            },
        },
        created() {
            this.startMDM()
            this.setCompanyID(this.companyID)
        },
        methods: {
            ...mapActions(useMDMStore, [
                'setCompanyID',
                'fetchCompanyHeadsets',
                'handleStartFormation',
                'handleStream',
                'startMDM',
            ]),
            /**
             * handle change filter on the table
             * @env web only
             * @param filter
             */
            handleFilters(filter) {
                this.fetchCompanyHeadsets(this.companyID, filter, false, true)
            },
        },
    }
</script>

<template>
    <headsets-table-list
        :company="company"
        :items="syncedHeadsets"
        :loading="MDM_LOADING && MDM_LOADING !== -1"
        :scoped-former="user.admin ? null : user"
        @start-formation="handleStartFormation"
        @stream="handleStream"
        @update:filters="handleFilters"
    />
    <router-view />
</template>

<style scoped lang="scss"></style>
