export const MyUsersRoutesNames = {
    index: 'my-users',
    profile: 'my-users.profile',
    learners: 'my-users.learners',
    learnerProfile: 'my-users.learnerProfile',
    formers: 'my-users.formers',
    formerProfile: 'my-users.formerProfile',
    guest: 'my-users.guest',
    infoTab: 'my-users.infoTab',
    formationTab: 'my-users.formationTab',
    sessionTab: 'my-users.sessionTab',
}
