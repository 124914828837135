<script>
    import PackChip from '@components/Packs/PackChip.vue'
    import HorizontalScrollContainer from '@components/tools/HorizontalScrollContainer.vue'
    import { useAppStore } from '@store/app.js'
    import { mapState } from 'pinia'

    export default {
        name: 'PacksFilter',
        components: { HorizontalScrollContainer, PackChip },
        props: {
            packs: {
                type: Array,
                default: () => [],
            },

            selected: {},

            multiple: {
                type: Boolean,
                default: true,
            },
            persist: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState(useAppStore, ['isTablet']),
            value: {
                get() {
                    return this.selected
                },
                set(value) {
                    if (this.persist)
                        sessionStorage.setItem(
                            this.persistKey,
                            JSON.stringify(value)
                        )
                    this.$emit('update:selected', value)
                },
            },
            allLabel() {
                return this.$t('packs._whole')
            },
            persistKey() {
                return btoa(this.$route.name + '_packs')
            },
        },
        mounted() {
            if (this.persist) {
                if (sessionStorage.getItem(this.persistKey)) {
                    this.value = JSON.parse(
                        sessionStorage.getItem(this.persistKey)
                    )
                } else
                    sessionStorage.setItem(
                        this.persistKey,
                        JSON.stringify(this.value)
                    )
            }
        },
        methods: {
            isSelected(pack) {
                if (this.multiple) {
                    return this.value.some((p) => p.id == pack.id)
                } else {
                    return this.value.id == pack.id
                }
            },
            toggle(pack) {
                if (this.multiple) {
                    if (this.isSelected(pack)) {
                        this.value = this.value.filter((p) => p.id != pack.id)
                    } else {
                        this.value = [...this.value, pack]
                    }
                } else {
                    this.value = pack
                }
            },
            selectAll() {
                this.value = []
            },
        },
    }
</script>

<template>
    <ra-box class="pack-filter">
        <horizontal-scroll-container>
            <pack-chip
                v-if="multiple"
                :active="!value.length"
                :pack="{
                    id: 0,
                    name: allLabel,
                    left_color: 'var(--primary)',
                    right_color: 'var(--primary)',
                }"
                rounded
                variant="text"
                @click="selectAll"
                @touchstart="selectAll"
                data-test="pack-filter-all"

            />
            <pack-chip
                v-for="pack in packs"
                :key="pack.id"
                :active="isSelected(pack)"
                :pack="pack"
                rounded
                :variant="isTablet ? 'text' : 'gradient'"
                @click="toggle(pack)"
                @touchstart="toggle(pack)"
                :data-test="pack.name"
            />
        </horizontal-scroll-container>
    </ra-box>
</template>

<style lang="scss">
    .pack-filter {
        .pack-chip {
            flex: 0 0 auto;
            margin: 0 0.5em;
            cursor: pointer;
        }
    }

    [data-device='web'] {
        .pack-filter {
            .pack-chip {
                border: 1.5px solid #8787b2;
                &:not(.pack-chip-disabled) {
                    border: 1.5px solid var(--left-color);
                }

                &.pack-chip-disabled img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(13%)
                        saturate(814%) hue-rotate(201deg) brightness(101%)
                        contrast(90%);
                }
            }
        }
    }
</style>
