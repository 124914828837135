import { formersRoutesMeta as meta } from './meta.js'
import { LearnersRoutesNames } from './Learners.name.js'

export default [
    // {
    //     path: '/companies/:company_id/learners/:learner_id',
    //     component: () => import('@pages/Learners/LearnerShowPage.vue'),
    //     children: [
    //         {
    //             path: '',
    //             name: LearnersRoutesNames.show,
    //             redirect: { name: LearnersRoutesNames.profile },
    //             meta,
    //         },
    //         {
    //             path: 'profile',
    //             name: LearnersRoutesNames.profile,
    //             meta: {
    //                 relatedName: LearnersRoutesNames.show,
    //                 ...meta,
    //             },
    //             component: () => import('@components/Learners/LearnerView.vue'),
    //         },
    //     ],
    // },
    {
        path: '/companies/:company_id/learners/import',
        children: [
            {
                path: '',
                name: LearnersRoutesNames.import,
                meta,
                component: () =>
                    import('@pages/Learners/LearnerImportPage.vue'),
            },
        ],
    },
]
