<script>
    import { Headset } from '@libs/MDM/core/Headset.js'

    export default {
        props: {
            headset: {
                type: Headset,
                required: true,
            },
            compact: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>
<template>
    <div class="connected-status">
        <ra-icon
            :color="headset.statusColor"
            size="10"
        >
            {{ 'mdi-circle' }}
        </ra-icon>
        <ra-typo
            v-if="!compact"
            italic
            semi
            variant="small"
            :class="['text-' + headset.statusColor]"
        >
            {{ headset.statusLabel }}
        </ra-typo>
    </div>
</template>
<style lang="scss">
    .connected-status {
        color: var(--primary);
        position: relative;
        font-size: 13px;
        display: flex;
        gap: 10px;
        align-items: center;
        .v-icon {
            font-size: 8px;
        }
        .ra-typography {
            line-height: 1.4em;
            font-size: 12px;
        }
    }
</style>
