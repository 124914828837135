<template>
<ra-box class="callout" rounded padding="3" :style="computedStyles">
    <ra-typo variant="small"><slot></slot></ra-typo>
</ra-box>
</template>

<style scoped lang="scss">
.callout{
    background: var(--bg);
    border : 1.5px solid var(--border);
    color: var(--color);
}
</style>
<script>
    export default {
        props:{
            bg:{
                type:String,
                default:"white-60-alpha"
            },

            border:{
                type:String,
                default:"primary-20-alpha"
            },

            color:{
                type:String,
                default:"primary-80-alpha"
            }
        },

        computed:{
            computedStyles(){
                return{
                    "--bg":`var(--${this.bg})`,
                    "--border":`var(--${this.border})`,
                    "--color":`var(--${this.color})`
                }
            }
        }
    }
</script>