const DEFAULT_SETTINGS = {
    interactionMode: 'controller',
    controllerPreferences: 'right',
    language: 'fr',
    launchTraining: 'all',
    learnerCanChooseModule: true,
    quiz: {
        vr: true,
        el: true,
    },
    sendSynthesisSheet: true,
    update:{
        formation: true,
        app: true
    }
}

export class HeadsetSettings{
    settings = structuredClone(DEFAULT_SETTINGS)
    editingSettings = structuredClone(DEFAULT_SETTINGS)
    init = true
    headset = null
    constructor(headset) {
        this.headset = headset
    }

    get value(){
        return this.editingSettings
    }

    set value(settings){
        this.editingSettings = {...structuredClone(DEFAULT_SETTINGS), ...settings}
    }



    patch(settings) {
        if(!settings) return;
        this.settings = {
            ...this.settings,
            ...settings
        }

        if(this.init) {
            this.init = false
            this.editingSettings = {
                ...structuredClone(this.settings)
            }
        }
    }

    apply() {
        this.settings = {
            ...this.editingSettings
        }
        this.headset.state.settings = this.settings
        this.headset.saveState()
    }

}