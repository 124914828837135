<script>
    import MDMHeader from '@components/Headsets/Pilotage/MDMHeader.vue'

    export default {
        name: 'HeadsetStreamLayout',
        components: {
            MDMHeader,
        },
        emits: ['back'],
    }
</script>

<template>
    <web>
        <ra-box
            class="headset-stream-layout"
            height="100%"
            bg="grey-900"
        >
            <ra-stack
                class="web-stack-layout"
                direction="horizontal"
                distribution="fill"
                gap="5"
                padding="4"
                mobile-swap
            >
                <slot name="aside"></slot>

                <slot></slot>
            </ra-stack>
        </ra-box>
    </web>
    <tablet>
        <div class="headset-stream-layout application-layout">
            <tablet>
                <aside>
                    <ra-drawer>
                        <MDMHeader />
                        <ra-box padding="5,">
                            <slot name="aside"></slot>
                        </ra-box>
                    </ra-drawer>
                </aside>
            </tablet>

            <main>
                <slot></slot>
            </main>
        </div>
    </tablet>
</template>

<style lang="scss">
    [data-device='tablet'] .headset-stream-layout {
        display: flex;
    }

    .headset-stream-layout .ra-list-item:first-child {
        border-top: none;
    }

    [data-device='web'] .headset-stream-layout {
        @media screen and (max-width: 760px) {
            height: auto !important;
            .web-stack-layout {
                & > .ra-stack {
                    flex: 1 1 auto;
                }
            }
        }
    }

    .web-stack-layout {
        height: 100vh;

        & > .ra-stack {
            border: solid 0.5px rgba(9, 9, 84, 0.39);
            &:first-child {
                flex: 0 0 350px;
            }
        }
    }
</style>
